import React from "react";
import loadable from "@loadable/component";
import { Container } from "@atoms";
import { HubspotForm } from "@molecules";
import clsx from "clsx";

const ContentBuilder = loadable(() => import("@organisms/ContentBuilder"));

const EventContent = ({ blocks, form, hero }) => {
  return (
    <Container smallerPadding>
      <div className="flex flex-wrap md:flex-nowrap">
        <div
          className={clsx(
            "mx-auto w-full max-w-lg flex-shrink-0 lg:max-w-xl xxl:max-w-2xl",
            {
              "md:-ml-8": form.formId,
            }
          )}
        >
          <ContentBuilder blocks={blocks} hasHero={hero.heading} />
        </div>
        {form.formId && (
          <div className="mt-10">
            <HubspotForm
              color="purple"
              {...form}
              fluid
              light
              newsletterCheckbox
              lightButton
            />
          </div>
        )}
      </div>
    </Container>
  );
};

export default EventContent;
